import React from 'react'
import Layout from '../../layouts/layout.js'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Pagetitle from '../../components/Pagetitle'

const Portfolio = ({ data }) => {
  return (
    <Layout>
      <Helmet title="Portfolio" />
      <Pagetitle title="Portfolio" />
      <div key={data.allDatoCmsPortfolio.id}>
        {data.allDatoCmsPortfolio.edges.map(({ node: portfolio }) => (
          <div className="block">
            <Link to={`portfolio/${portfolio.slug}`}>
              <h2 className="text-primary font-semibold hover:text-primarylighter my-4">{portfolio.title}</h2>
            </Link>
            {portfolio.childDatoCmsTextNode.childMarkdownRemark.excerpt}
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const ListGraphquery = graphql`
  {
    allDatoCmsPortfolio {
      edges {
        node {
          id
          slug
          title
          url
          content
          childDatoCmsTextNode {
            childMarkdownRemark {
              excerpt(truncate: true)
            }
          }
        }
      }
    }
  }
`

export default Portfolio
